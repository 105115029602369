const TheftIcon = (props: React.ComponentProps<"svg">) => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" {...props}>
    <path
      d="M14.1429 4.5L29 16.3812L9.5 25.5891L28.0714 34.5"
      stroke="currentColor"
      strokeWidth="6"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.8726 17.5C12.9402 14.0257 16.1747 11.5 19.999 11.5C24.6934 11.5 28.499 15.3056 28.499 20H30.499C30.499 14.201 25.798 9.5 19.999 9.5C15.0615 9.5 10.92 12.908 9.79847 17.5H11.8726ZM11.499 20H9.49902C9.49902 25.799 14.2 30.5 19.999 30.5C24.9365 30.5 29.078 27.092 30.1996 22.5H28.1254C27.0579 25.9743 23.8234 28.5 19.999 28.5C15.3046 28.5 11.499 24.6944 11.499 20Z"
      fill="#111827"
    />
    <path d="M9.5 11.5L15.5 17.5H9.5V11.5Z" fill="#111827" />
    <path d="M30.5 28.5L24.5 22.5H30.5V28.5Z" fill="#111827" />
    <circle cx="20" cy="20" r="2" fill="#111827" />
  </svg>
);
export default TheftIcon;
