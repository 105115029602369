const BuyBikeIcon = (props: React.ComponentProps<"svg">) => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3.5 18.8721L9.77679 6.5L33 22.18L6.13393 34.5"
      stroke="currentColor"
      strokeWidth="6"
    />
    <path
      d="M15.7305 13C15.7305 10.7909 17.5213 9 19.7305 9V9C21.9396 9 23.7305 10.7909 23.7305 13V15H15.7305V13Z"
      stroke="#111827"
      strokeWidth="1.83333"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.8135 24.037V19H20.6469V24.037L22.082 22.1018L23.3784 23.3982L19.7302 27.0464L16.082 23.3982L17.3784 22.1018L18.8135 24.037Z"
      fill="#111827"
    />
    <rect x="14.7305" y="16" width="2" height="2" fill="#111827" />
    <rect x="22.7305" y="16" width="2" height="2" fill="#111827" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26.2127 31H13.2512H13.2105H13.2105C12.6352 31 12.1418 31 11.7382 30.9659C11.3146 30.93 10.8925 30.8507 10.4939 30.6331C9.90362 30.3109 9.43873 29.7996 9.17388 29.1814C9.14806 29.1212 9.12516 29.0607 9.10488 29H13.2512H15.731H26.2127C26.84 29 27.2479 28.9992 27.5569 28.973C27.8553 28.9477 27.9622 28.9047 28.0116 28.8777C28.2084 28.7703 28.3633 28.5999 28.4516 28.3938C28.4738 28.3421 28.5065 28.2316 28.5033 27.9321C28.5001 27.622 28.4623 27.2159 28.4028 26.5914L27.5607 17.75C27.5146 17.2656 27.4847 16.9595 27.4443 16.7283C27.406 16.5093 27.3702 16.4369 27.3521 16.4065C27.2675 16.2647 27.1426 16.1511 26.9934 16.0804C26.9614 16.0652 26.8859 16.0364 26.6643 16.019C26.4303 16.0007 26.1227 16 25.6361 16H13.8277C13.3411 16 13.0336 16.0007 12.7996 16.019C12.5779 16.0364 12.5025 16.0652 12.4705 16.0804C12.3213 16.1511 12.1964 16.2647 12.1118 16.4065C12.0937 16.4369 12.0579 16.5093 12.0196 16.7283C11.9791 16.9595 11.9493 17.2656 11.9031 17.75L11.0611 26.5914C11.047 26.7393 11.0341 26.875 11.0226 27H9.01447C9.0299 26.8237 9.04761 26.6378 9.06624 26.4423L9.07009 26.4018L9.91213 17.5604L9.91551 17.5249C9.95725 17.0864 9.9939 16.7015 10.0495 16.3838C10.1086 16.0454 10.2008 15.7062 10.394 15.3822C10.6817 14.8998 11.1062 14.5138 11.6136 14.2732C11.9545 14.1116 12.3009 14.052 12.6434 14.0252C12.9649 14 13.3516 14 13.792 14H13.8277H25.6361H25.6718C26.1123 14 26.4989 14 26.8204 14.0252C27.1629 14.052 27.5093 14.1116 27.8502 14.2732C28.3577 14.5138 28.7822 14.8998 29.0698 15.3822C29.2631 15.7062 29.3552 16.0454 29.4144 16.3838C29.47 16.7015 29.5066 17.0865 29.5484 17.525V17.525L29.5517 17.5604L30.3938 26.4018L30.3976 26.4424C30.4522 27.0151 30.499 27.5062 30.5032 27.9112C30.5077 28.3363 30.4688 28.764 30.29 29.1814C30.0251 29.7996 29.5602 30.3109 28.97 30.6331C28.5714 30.8507 28.1492 30.93 27.7256 30.9659C27.3221 31 26.8287 31 26.2534 31H26.2534H26.2127Z"
      fill="#111827"
    />
  </svg>
);
export default BuyBikeIcon;
