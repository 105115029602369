import { getUnixTime } from "date-fns";

const API_URL = process.env.NEXT_PUBLIC_API_URL;
const POSTCODE_API_URL = "https://api.ideal-postcodes.co.uk/v1";

export const makeQueryString: (
  queryObject: Record<string, string | string[] | undefined>
) => string = (queryObject) => {
  const queryParams = new URLSearchParams();
  Object.entries(queryObject).forEach(([key, value]) => {
    if (value !== undefined) {
      queryParams.append(key, value.toString());
    }
  });
  const queryString = queryParams.toString();

  return queryString ? "?" + queryString : "";
};

const endPoints = {
  login: () => `${API_URL}/auth/`,
  loginWithRefreshToken: () => `${API_URL}/access-with-refresh-token/`,
  createVerificationSession: () => `${API_URL}/create-verification-session`,
  confirmVerificationSession: () => `${API_URL}/confirm-verification-session`,
  getKustomerToken: () => `${API_URL}/kustomer_token`,
  postcodeAutocomplete: (q?: string) =>
    `${POSTCODE_API_URL}/autocomplete/addresses${makeQueryString({
      api_key: process.env.NEXT_PUBLIC_IDEAL_POSTCODES_API_KEY,
      q,
    })}`,
  udprn: (p?: string) => `${API_URL}/udprn/${p}`,
  getCancellationSummary: (
    expectedCancellationDate: Date,
    withoutCollectionFee: boolean,
    chargeNewFlexiblePlan?: boolean
  ) => {
    const cancellation_date_utc = getUnixTime(
      expectedCancellationDate
    ).toString();
    const without_collection_fee = withoutCollectionFee ? "true" : undefined;
    const charge_new_flexible_plan = chargeNewFlexiblePlan ? "true" : undefined;
    return `${API_URL}/cancellation_summary${makeQueryString({
      cancellation_date_utc,
      without_collection_fee,
      charge_new_flexible_plan,
    })}`;
  },
  cancelSubscription: () => `${API_URL}/cancel_subscription`,
  getUserVariant: (experimentCode: string) =>
    `${API_URL}/get_user_variant/${experimentCode}`,
};

export default endPoints;
