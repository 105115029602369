import * as React from "react";

export const CloseIcon = (props: React.ComponentProps<"svg">) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
    <path
      d="M20 20L14 14M4 4C7.58287 7.58287 8.85287 8.85287 12 12"
      stroke="currentColor"
      strokeWidth="2"
    />
    <path d="M4 20L20 4" stroke="currentColor" strokeWidth="2" />
  </svg>
);
