import { JZ, typeForStyle } from "@buzzbike/ui/src/DesignSystem";
import {
  Box,
  Breadcrumb as CharkaBreadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbProps as CharkaBreadcrumbProps,
  Text,
} from "@chakra-ui/react";
import { useRouter } from "next/router";

type Item = {
  name: string;
  label: string;
  path: string;
};

type BreadcrumbProps = CharkaBreadcrumbProps & {
  items: Item[];
  currentPage: string;
  colorVariant?: "primary" | "secondary";
};

type ColorVariant = Record<
  string,
  {
    textColor: string;
    borderColor: string;
    activeBorderColor: string;
    activedBorderColor: string;
  }
>;

const colorVariants: ColorVariant = {
  primary: {
    textColor: JZ.Pink,
    borderColor: JZ.Background,
    activedBorderColor: JZ["Pink/1.5"],
    activeBorderColor: JZ.Pink,
  },
  secondary: {
    borderColor: JZ.Background,
    textColor: "#2D3748",
    activedBorderColor: JZ.Mint,
    activeBorderColor: JZ["Mint/2"],
  },
};

export function Breadcrumb({
  items,
  currentPage,
  colorVariant = "primary",
}: BreadcrumbProps) {
  const color = colorVariants[colorVariant];
  const router = useRouter();

  return (
    <Box flex={1}>
      <CharkaBreadcrumb spacing="8px" separator="" w="100%">
        {items.map(({ name, label, path }, index) => {
          const isCurrentPage = name === currentPage;
          const currentIndex = items.findIndex((i) => i.name === currentPage);
          return (
            <BreadcrumbItem
              key={name}
              w={`calc(100%/${items.length})`}
              isCurrentPage={isCurrentPage}
            >
              <BreadcrumbLink
                flex="1"
                _hover={{
                  cursor: currentIndex > index ? "pointer" : "auto",
                }}
                onClick={() => {
                  if (currentIndex > index) {
                    router.push({
                      pathname: path,
                      query: {
                        ...router.query,
                        isBack: true,
                      },
                    });
                  }
                }}
                role="group"
              >
                <Box
                  h={1}
                  bg={
                    isCurrentPage
                      ? color.activeBorderColor
                      : currentIndex > index
                      ? color.activedBorderColor
                      : color.borderColor
                  }
                  rounded={8}
                  mb={2}
                  _groupHover={
                    currentIndex > index ? { bg: color.activeBorderColor } : {}
                  }
                />
                <Text
                  {...typeForStyle("PrimaryMedium", "Base")}
                  color={isCurrentPage ? color.textColor : "#718096"}
                  _groupHover={
                    currentIndex > index
                      ? { color: JZ["Grey text and icon"], opacity: 1 }
                      : {}
                  }
                  opacity={isCurrentPage ? 1 : 0}
                  display={["none", "none", "none", "none", "block"]}
                >
                  {label}
                </Text>
              </BreadcrumbLink>
            </BreadcrumbItem>
          );
        })}
      </CharkaBreadcrumb>
      <Text
        color={color.textColor}
        {...typeForStyle("PrimaryMedium", "Base")}
        display={["block", "block", "block", "block", "none"]}
      >
        {items.find((i) => i.name === currentPage)?.label}
      </Text>
    </Box>
  );
}

export function CheckoutBreadcrumb({ currentPage }: { currentPage: string }) {
  return (
    <Breadcrumb
      items={[
        {
          name: "step1",
          label: "Bike & plan",
          path: "60-plan",
        },
        {
          name: "step2",
          label: "Delivery & account",
          path: "70-account",
        },
        {
          name: "step3",
          label: "Cycling essentials",
          path: "71-kitshop",
        },
        {
          name: "step4",
          label: "Checkout safely",
          path: "80-pay",
        },
      ]}
      currentPage={currentPage}
      colorVariant="secondary"
    />
  );
}

export default Breadcrumb;
