const RepairsIcon = (props: React.ComponentProps<"svg">) => (
  <svg width="41" height="40" viewBox="0 0 41 40" fill="none" {...props}>
    <path
      d="M7.59091 34L5 15.5246L15.7338 16.7295L33.5 9.5L19.4351 34L14.9935 26.3689"
      stroke="currentColor"
      strokeWidth="6"
    />
    <path
      d="M17.5 23.1044L16.5603 22.5168C14.7182 21.365 13.5 19.3241 13.5 17.0002C13.5 14.9201 14.478 13.0661 16 11.8758V15.0002C16 16.3809 17.1193 17.5002 18.5 17.5002H21.5C22.8807 17.5002 24 16.3809 24 15.0002V11.8758C25.522 13.0661 26.5 14.9201 26.5 17.0002C26.5 19.3241 25.2818 21.365 23.4397 22.5168L22.5 23.1044V29.5002L15.5 29.5001V31.5002H24.5V24.2126C26.9022 22.7107 28.5 20.042 28.5 17.0002C28.5 13.7519 26.6779 10.9291 24 9.49829C23.7434 9.36118 23.4789 9.23684 23.2074 9.12615C22.6082 8.8818 22 9.35952 22 10.0067V15.0002C22 15.2763 21.7761 15.5002 21.5 15.5002H18.5C18.2239 15.5002 18 15.2763 18 15.0002V10.0067C18 9.35952 17.3918 8.8818 16.7926 9.12615C16.5211 9.23684 16.2566 9.36118 16 9.49829C13.3221 10.9291 11.5 13.7519 11.5 17.0002C11.5 20.042 13.0978 22.7107 15.5 24.2126V27.5001H17.5V23.1044Z"
      fill="#111827"
    />
  </svg>
);
export default RepairsIcon;
