import { Button, ButtonProps } from "@chakra-ui/react";
import React from "react";

import { typeForStyle } from "../../../ui/src/DesignSystem";

const ButtonLink = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (props, ref) => (
    <Button
      variant="link"
      textDecoration="underline"
      {...typeForStyle("PrimaryRegular", "Base")}
      textTransform="none"
      {...props}
      ref={ref}
    >
      {props.children}
    </Button>
  )
);
export default ButtonLink;
