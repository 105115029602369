const RewardIcon = (props: React.ComponentProps<"svg">) => (
  <svg
    width="41"
    height="40"
    viewBox="0 0 41 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M24.4524 6L11.5 17.4851L28.5 26.3861L12.3095 35"
      stroke="currentColor"
      strokeWidth="6"
    />
    <path
      d="M27.583 9H32.2497V13.6667C32.2497 16.244 30.1603 18.3333 27.583 18.3333V18.3333V9Z"
      stroke="#111827"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M12.917 9H8.25033V13.6667C8.25033 16.244 10.3397 18.3333 12.917 18.3333V18.3333V9Z"
      stroke="#111827"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M21.2503 26H19.2503V29.3333C19.2503 29.7015 18.9518 30 18.5837 30H13.917V32H26.5837V30H21.917C21.5488 30 21.2503 29.7015 21.2503 29.3333V26Z"
      fill="#111827"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.916 10H26.5827V18.3333C26.5827 21.8311 23.7472 24.6667 20.2493 24.6667C19.9091 24.6667 19.5751 24.6398 19.2493 24.5882V26.6073C19.5772 26.6465 19.9109 26.6667 20.2493 26.6667C24.8517 26.6667 28.5827 22.9357 28.5827 18.3333V10V8H26.5827H13.916H11.916V10V18.3333C11.916 21.8781 14.1293 24.906 17.2493 26.1103V23.9125C15.2648 22.8431 13.916 20.7457 13.916 18.3333V10Z"
      fill="#111827"
    />
  </svg>
);
export default RewardIcon;
